import React, { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function About() {


  const [progressForThird, setProgressForThird] = useState(0);
  const targetPercentageForThird = 60;
  useEffect(() => {
    const timer = setInterval(() => {
      setProgressForThird((prevProgress) => {
        const newProgress = Math.min(prevProgress + 1, targetPercentageForThird);
        if (newProgress === targetPercentageForThird) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 40); // Adjust the interval to control the speed of the animation

    return () => {
      clearInterval(timer);
    };
  }, [targetPercentageForThird]);


  const [progressForSecond, setProgressForSecond] = useState(0);
  const targetPercentageForSecond = 65;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressForSecond((prevProgress) => {
        const newProgress = Math.min(prevProgress + 1, targetPercentageForSecond);
        if (newProgress === targetPercentageForSecond) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 40); // Adjust the interval to control the speed of the animation

    return () => {
      clearInterval(timer);
    };
  }, [targetPercentageForSecond]);


  const [progressForFirst, setProgressForFirst] = useState(0);
  const targetPercentageForFirst = 78;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressForFirst((prevProgress) => {
        const newProgress = Math.min(prevProgress + 1, targetPercentageForFirst);
        if (newProgress === targetPercentageForFirst) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 40); // Adjust the interval to control the speed of the animation

    return () => {
      clearInterval(timer);
    };
  }, [targetPercentageForFirst]);

  return (
    <section className="about-style-two">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div id="image_block_03">
              <div className="image-box">
                <div
                  className="pattern-layer wow zoomIn animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                  style={{
                    backgroundImage: "url(assets/images/icons/pattern-17.png)"
                  }}
                />
                <figure className="image js-tilt">
                  <img src="assets/images/resource/illastration-2.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div id="content_block_03">
              <div className="content-box">
                <div className="sec-title">
                  <div className="title-two">
                    <p>
                      <span>{"{"}</span>&nbsp;About our SERVICE&nbsp;
                      <span>{"}"}</span>
                    </p>
                  </div>
                  <h3>
                    FIX RENTAL <span>is Skilled company for doing better</span> RENTING SERVICES
                  </h3>
                </div>
                <div className="text">
                  <p>
                    We are more than just a machinery rental service, we are your partner in achieving project success. Our commitment to excellence drives us to continuously improve our services, ensuring that you receive the best possible experience.
                  </p>
                </div>
                <div className="inner-box clearfix">
                  <div className="single-progress-box">
                    <div className="box">
                      <CircularProgressbar
                        value={progressForFirst}
                        text={`${progressForFirst}%`}
                        strokeWidth={12}
                        styles={{
                          path: {
                            stroke: '#007bff', // Change this to your desired color
                          },
                          text: {
                            fill: '#000', // Change this to your desired color
                            fontSize: '16px',
                            fontWeight: "bold"
                          },
                          trail: {
                            stroke: '#d6d6d6', // Change this to your desired color
                          },
                        }}
                      />
                      <p style={{ color: "#000", fontWeight:"bold" }}>Relaibality</p>
                    </div>
                  </div>
                  <div className="single-progress-box">
                    <div className="box">
                      <CircularProgressbar
                        value={progressForSecond}
                        text={`${progressForSecond}%`}
                        strokeWidth={12}
                        styles={{
                          path: {
                            stroke: '#fd7e14', // Orange color for the path
                          },
                          text: {
                            fill: '#000', // Black color for the text
                            fontSize: '16px',
                            fontWeight: 'bold',
                          },
                          trail: {
                            stroke: '#d6d6d6', // Light gray color for the trail
                          },
                        }}
                      />
                      <p style={{ color: "#000", fontWeight:"bold" }}>Regions Covered</p>
                    </div>
                  </div>
                  <div className="single-progress-box">
                    <div className="box">
                      <CircularProgressbar
                        value={progressForThird}
                        text={`${progressForThird}%`}
                        strokeWidth={12}
                        styles={{
                          path: {
                            stroke: '#82a9fc', // Orange color for the path
                          },
                          text: {
                            fill: '#000', // Black color for the text
                            fontSize: '16px',
                            fontWeight: 'bold',
                          },
                          trail: {
                            stroke: '#d6d6d6', // Light gray color for the trail
                          },
                        }}
                      />

                      <p style={{ color: "#000", fontWeight:"bold" }}>Growth</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
