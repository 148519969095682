import React from 'react'

export default function Portfolio() {
  return (
    <section className="portfolio-section style-two">
    <div className="auto-container">
      <div className="sec-title centred">
        <div className="title-two">
          <p>
            <span>{"{"}</span>&nbsp;Gallery&nbsp;<span>{"}"}</span>
          </p>
        </div>
      </div>
      <div className="sortable-masonry">
        {/* <div className="filters">
          <ul className="filter-tabs filter-btns centred clearfix">
            <li className="active filter" data-role="button" data-filter=".all">
              All
            </li>
            <li className="filter" data-role="button" data-filter=".strategy">
              Strategy
            </li>
            <li
              className="filter"
              data-role="button"
              data-filter=".facilitation"
            >
              Facilitation
            </li>
            <li className="filter" data-role="button" data-filter=".coaching">
              Coaching
            </li>
            <li
              className="filter"
              data-role="button"
              data-filter=".stakeholder "
            >
              Stakeholder
            </li>
          </ul>
        </div> */}
        <div className="items-container row clearfix">
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all coaching strategy facilitation">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/2.jpg" alt="" />
                </figure>
                <div className="content-box">
                  {/* <h5>
                    <a href="portfolio.html">Chan Agency</a>
                  </h5>
                  <span>Strategy</span> */}
                  <a
                    href="assets/images/gallery-1/2.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all strategy stakeholder">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/3.jpg" alt="" />
                </figure>
                <div className="content-box">
                  {/* <h5>
                    <a href="portfolio.html">Chan Agency</a>
                  </h5>
                  <span>Strategy</span> */}
                  <a
                    href="assets/images/gallery-1/3.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all coaching facilitation">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/3.jpg" alt="" />
                </figure>
                <div className="content-box">
                  <a
                    href="assets/images/gallery-1/3.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all strategy stakeholder">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/4.jpg" alt="" />
                </figure>
                <div className="content-box">
                  {/* <h5>
                    <a href="portfolio.html">Chan Agency</a>
                  </h5>
                  <span>Strategy</span> */}
                  <a
                    href="assets/images/gallery-1/4.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all coaching strategy facilitation">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/5.jpg" alt="" />
                </figure>
                <div className="content-box">
                  {/* <h5>
                    <a href="portfolio.html">Chan Agency</a>
                  </h5>
                  <span>Strategy</span> */}
                  <a
                    href="assets/images/gallery-1/5.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all strategy coaching stakeholder">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/WhatsApp_Image_2021-10_38_19.jpg" alt="" />
                </figure>
                <div className="content-box">
                  {/* <h5>
                    <a href="portfolio.html">Chan Agency</a>
                  </h5>
                  <span>Strategy</span> */}
                  <a
                    href="assets/images/gallery-1/WhatsApp_Image_2021-10_38_19.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all coaching facilitation">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/2.jpg" alt="" />
                </figure>
                <div className="content-box">
                  {/* <h5>
                    <a href="portfolio.html">Chan Agency</a>
                  </h5>
                  <span>Strategy</span> */}
                  <a
                    href="assets/images/gallery-1/2.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all strategy coaching stakeholder">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/gallery-1/3.jpg" alt="" />
                </figure>
                <div className="content-box">
                  {/* <h5>
                    <a href="portfolio.html">Chan Agency</a>
                  </h5>
                  <span>Strategy</span> */}
                  <a
                    href="assets/images/gallery-1/3.jpg"
                    className="view-btn lightbox-image
                        "
                    data-fancybox="gallery"
                  >
                    <i style={{fontSize:"25px"}} className="fa-solid fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="load-btn text-center">
        <a href="index-4.html" className="theme-btn style-four">
          Load more
        </a>
      </div> */}
    </div>
  </section>
  )
}
