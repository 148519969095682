import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import Captcha from './Captcha';
import axios from 'axios';
import './style.css';


export default function Contact() {
    const [captchaValue, setCaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [failed, setFailed] = useState(false);

    const [captchaText, setCaptchaText] = useState('');
    const [userInput, setUserInput] = useState('');
    const canvasRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        initializeCaptcha(ctx);
    }, []);

    const generateRandomChar = (min, max) =>
        String.fromCharCode(Math.floor
            (Math.random() * (max - min + 1) + min));

    const generateCaptchaText = () => {
        let captcha = '';
        for (let i = 0; i < 2; i++) {
            captcha += generateRandomChar(65, 90);
            captcha += generateRandomChar(97, 122);
            captcha += generateRandomChar(48, 57);
        }
        return captcha.split('').sort(
            () => Math.random() - 0.5).join('');
    };

    const drawCaptchaOnCanvas = (ctx, captcha) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const textColors = ['#fff', '#fff'];
        const letterSpace = 150 / captcha.length;
        for (let i = 0; i < captcha.length; i++) {
            const xInitialSpace = 25;
            ctx.font = '20px Roboto Mono';
            ctx.fillStyle = textColors[Math.floor(
                Math.random() * 2)];
            ctx.fillText(
                captcha[i],
                xInitialSpace + i * letterSpace,

                // Randomize Y position slightly
                Math.floor(Math.random() * 16 + 25),
                100
            );
        }
    };

    const initializeCaptcha = (ctx) => {
        setUserInput('');
        const newCaptcha = generateCaptchaText();
        setCaptchaText(newCaptcha);
        drawCaptchaOnCanvas(ctx, newCaptcha);
    };

    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(()=>{

    },[submitted, setSubmitted])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFailed(false);
        setSubmitted(false);
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setLoading(false)
            return;
        }
        setErrors({});
        try {
            const data = await axios.post('https://kearth-deploy.onrender.com/api/enquiry', formData);
            console.log(data)
            setSubmitted(true);
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
              });
              setErrors({});
              setLoading(false);
        } catch (error) {
            console.error('Error sending email', error);
            setFailed(true);
            setLoading(false)
        }
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'First name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = 'Email is invalid';
        if (!formData.phone) formErrors.phone = 'Phone number is required';
        if (!/^\d{10}$/.test(formData.phone)) formErrors.phone = 'Phone number is invalid';
        if (!formData.subject) formErrors.subject = 'Subject is required';
        if (!formData.message) formErrors.message = 'Message is required';
        if (!userInput) formErrors.captchaText = "Captcha is required"
        if (userInput !== captchaText) formErrors.captchaText = "Please enter correct captcha text"
        return formErrors;
    };
    return (
        <section className="contact-section bg-color-1">
            <div className="auto-container">
                <div className="sec-title centred">
                    <div className="title-two">
                        <p>
                            <span>{"{"}</span>&nbsp;Contact us&nbsp;<span>{"}"}</span>
                        </p>
                    </div>
                    <h3>
                        Want to hire us to boost site
                        <br />
                        contact us any time!
                    </h3>
                </div>
                <div className="upper-content">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div
                                className="image-box wow slideInLeft animated"
                                data-wow-delay="00ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="icon-box">
                                    <div
                                        className="iocn icon-1"
                                        style={{
                                            backgroundImage: "url(assets/images/icons/icon-7.png)"
                                        }}
                                    />
                                    <div
                                        className="iocn icon-2 rotate-me"
                                        style={{
                                            backgroundImage: "url(assets/images/icons/icon-8.png)"
                                        }}
                                    />
                                </div>
                                <figure className="image js-tilt">
                                    <img src="assets/images/resource/illastration-3.png" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 form-column">
                            <div className="contact-form-inner">
                                <form onSubmit={handleSubmit} id="contact-form" className="default-form">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="First name *"
                                                value={formData.name}
                                                onChange={handleChange}
                                                style={errors.name && { border: "1px solid red" }}
                                            />
                                            {errors.name && <span className="error" style={{ color: "red" }}>{errors.name}</span>}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Your mail *"
                                                value={formData.email}
                                                onChange={handleChange}
                                                style={errors.email && { border: "1px solid red" }}
                                            />
                                            {errors.email && <span className="error" style={{ color: "red" }}>{errors.email}</span>}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="Your Phone *"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                style={errors.phone && { border: "1px solid red" }}
                                            />
                                            {errors.phone && <span className="error" style={{ color: "red" }}>{errors.phone}</span>}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input
                                                type="text"
                                                name="subject"
                                                placeholder="Subject *"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                style={errors.subject && { border: "1px solid red" }}
                                            />
                                            {errors.subject && <span className="error" style={{ color: "red" }}>{errors.subject}</span>}
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <textarea
                                                name="message"
                                                placeholder="Message..."
                                                value={formData.message}
                                                onChange={handleChange}
                                                style={errors.message && { border: "1px solid red" }}
                                            />
                                            {errors.message && <span className="error" style={{ color: "red" }}>{errors.message}</span>}
                                        </div>
                                        <div style={{ marginLeft: "17px", marginBottom: "25px", display: "flex", alignItems: "center", width: "100%", marginTop: "-10px" }}>

                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <canvas
                                                    ref={canvasRef}
                                                    width="190"
                                                    height="60"
                                                    style={{
                                                        backgroundColor: "#aeb8c1",
                                                        border: "1px solid #d2eff3",
                                                        borderRadius: 0,
                                                        marginTop: "15px",
                                                    }}
                                                ></canvas>
                                                <button
                                                    type='reset'
                                                    style={{ backgroundColor: "#0267ff", padding: "6px", height: "60px", marginTop: "15px", paddingLeft: "10px", paddingRight: "10px" }}
                                                    onClick={() => initializeCaptcha(canvasRef.current.getContext('2d'))}>
                                                    <i
                                                        style={{ color: "#fff" }}
                                                        className="fa-solid fa-arrows-rotate"
                                                    />
                                                </button>
                                            </div>
                                            <input
                                                type="text"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    border: `1px solid ${errors.captchaText ? "red" : "#d2eff3"}`,
                                                    width: "51.5%",
                                                    borderRadius: 0,
                                                    marginTop: "15px",
                                                    marginLeft: "6.5%",
                                                    height: "60px" // Match the height of the canvas
                                                }}
                                                placeholder="Enter the captcha..."
                                                value={userInput}
                                                onChange={handleUserInputChange}
                                            />
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                            <button
                                                className="theme-btn style-four"
                                                type="submit"
                                                name="submit-form"
                                                disabled={loading}  // Disable button while loading
                                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                {loading ? (
                                                    <div className="spinner" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-arrow-circle-right" />
                                                        Send now
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {submitted && <div className="success-message">Message sent successfully!</div>}
                                {failed && <div className="error-message" style={{color:"red"}}>!!Failed to send email!!</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-content">
                    <div className="col-lg-10 col-md-12 col-sm-12 offset-lg-1 inner-column">
                        <div className="inner-box clearfix">
                            <div
                                className="single-item wow fadeInUp animated"
                                data-wow-delay="00ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="inner">
                                    <div className="icon-box">
                                        <i className="fas fa-home" />
                                    </div>
                                    <h3>Our office address:</h3>
                                    <div className="text">
                                        <p>
                                        Kesar Tower, Ring Road No. - 2, 
                                        <br />
                                        Gondwara, Bhanpuri, Bilaspur Raod, Raipur, 
                                        <br />
                                        Chhattisgarh, India - 492003
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="single-item wow fadeInUp animated"
                                data-wow-delay="300ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="inner">
                                    <div className="icon-box">
                                        <i className="fas fa-envelope-open" />
                                    </div>
                                    <h3>Mail us:</h3>
                                    <div className="text">
                                        <a href="mailto:info@example.com">
                                        info@keartsolutions.com</a>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="single-item wow fadeInUp animated"
                                data-wow-delay="600ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="inner">
                                    <div className="icon-box">
                                        <i className="fas fa-phone" />
                                    </div>
                                    <h3>Call for help:</h3>
                                    <div className="text">
                                        <a href="tel:7049910585">+91 7049910585</a><br />
                                        <a href="tel:9589696525">+91 9589696525</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
