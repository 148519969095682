import React from 'react'

export default function TopNotification() {
    return (
        <div className="advertise-box centred">
            <div className="image-layer">
                <figure className="image image-1">
                    <img src="assets/images/icons/vector-1.png" alt="" />
                </figure>
                {/* <figure className="image image-2">
                    <img src="assets/images/icons/vector-2.png" alt="" />
                </figure> */}
                <figure className="image image-3">
                    <img src="assets/images/icons/vector-3.png" alt="" />
                </figure>
                <figure className="image image-4">
                    <img src="assets/images/icons/vector-4.png" alt="" />
                </figure>
                <figure className="image image-5">
                    <img src="assets/images/icons/vector-5.png" alt="" />
                </figure>
                <figure className="image image-6">
                    <img src="assets/images/icons/vector-6.png" alt="" />
                </figure>
                <figure className="image image-7">
                    <img src="assets/images/icons/vector-7.png" alt="" />
                </figure>
            </div>
            <div className="auto-container">
                <div className="text">
                    <h6>Welcome to the best deals in heavy machinery rentals—explore top-tier equipments!</h6>
                </div>
            </div>
        </div>
    )
}
