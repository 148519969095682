import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import WOW from 'wowjs';
import Parallax from 'parallax-js';
import TopNotification from './components/TopNotification';
import Header from './components/Header';
import Menu from './components/Menu';
import BannerSection1 from './components/BannerSection1';
import BannerSection2 from './components/About';
import Services from './components/Services';
import FunFact from './components/FunFact';
import Team from './components/Team';
import About from './components/About';
import Portfolio from './components/Portfolio';
import FAQ from './components/FAQ';
import Testimonial from './components/Testimonial';
import Contact from './components/Contact';
import News from './components/News';
import Clients from './components/Clients';
import Footer from './components/Footer';
import 'tilt.js';

const Home = () => {

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    function handlePreloader() {
      if ($('.preloader').length) {
        $('.preloader').delay(200).fadeOut(500);
      }
    }
    handlePreloader();

  }, [])

  useEffect(()=>{
    if ($('.wow').length) {
      var wow = new WOW.WOW(
        {
          boxClass: 'wow',      // animated element css class (default is wow)
          animateClass: 'animated', // animation css class (default is animated)
          offset: 0,          // distance to the element when triggering the animation (default is 0)
          mobile: false,       // trigger animations on mobile devices (default is true)
          live: true       // act on asynchronously loaded content (default is true)
        }
      );
      wow.init();
    }
  },[])

  useEffect(()=>{
    function onHoverthreeDmovement() {
      var tiltBlock = $('.js-tilt');
      if(tiltBlock.length) {
          $('.js-tilt').tilt({
              maxTilt: 20,
              perspective:700, 
              glare: true,
              maxGlare: 0
          })
      }
  }
  onHoverthreeDmovement();
},[])

useEffect(()=>{
    	//Parallax Scene for Icons
	if($('.parallax-scene-1').length){
		var scene = $('.parallax-scene-1').get(0);
		var parallaxInstance = new Parallax(scene);
	}
	if($('.parallax-scene-2').length){
		var scene = $('.parallax-scene-2').get(0);
		var parallaxInstance = new Parallax(scene);
	}
	if($('.parallax-scene-3').length){
		var scene = $('.parallax-scene-3').get(0);
		var parallaxInstance = new Parallax(scene);
	}
	if($('.parallax-scene-4').length){
		var scene = $('.parallax-scene-4').get(0);
		var parallaxInstance = new Parallax(scene);
	}
	if($('.parallax-scene-5').length){
		var scene = $('.parallax-scene-5').get(0);
		var parallaxInstance = new Parallax(scene);
	}
	if($('.parallax-scene-6').length){
		var scene = $('.parallax-scene-6').get(0);
		var parallaxInstance = new Parallax(scene);
	}
},[])

  return (
    <>
    <TopNotification />
      <div style={{ maxWidth: "100vw" }}>
        <div className="preloader" />
        <Header />
        <Menu />
        <section id="banner-section1">
          <BannerSection1 />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="services">
          <Services />
        </section>
        <section id="fun-fact">
          <FunFact />
        </section>
        <section id="team">
          <Team />
        </section>
        <section id="gallery">
          <Portfolio />
        </section>
        <section id="faq">
          <FAQ />
        </section>
        <section id="testimonial">
          <Testimonial />
        </section>
        <section id="contact">
          <Contact />
        </section>
        {/* <section id="news">
          <News />
        </section> */}
        {/* <section id="clients">
          <Clients />
        </section> */}
        <Footer />
        {isVisible && (
          <button
            className="scroll-top"
            onClick={scrollToTop}
            data-target="html"
          >
            <span className="fa fa-arrow-up" />
          </button>
        )}
      </div>
    </>
  );
}

export default Home
