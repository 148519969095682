import React from 'react'

export default function Team() {
  return (
    <section className="team-style-two text-center bg-color-2">
          <div
            className="pattern-layer"
            style={{ backgroundImage: "url(assets/images/icons/pattern-19.png)" }}
          />
          <div className="auto-container">
            <div className="sec-title light">
              <div className="title-two">
                <p>
                  <span>{"{"}</span>&nbsp;Team Members&nbsp;<span>{"}"}</span>
                </p>
              </div>
              <h3>
                Work with the awesome
                <br />
                team of our company
              </h3>
            </div>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                <div
                  className="team-block-two wow fadeInUp"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <figure className="image-box">
                      <a href="team-details.html">
                        <img src="assets/images/resource/team-1.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="lower-content">
                      <div className="box">
                        <h5>
                          <a href="team-details.html">Mr. Jasmeet Bhasin
                          </a>
                        </h5>
                        <span className="designation">Managing Director</span>
                        {/* <ul className="social-links clearfix">
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                <div
                  className="team-block-two wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <figure className="image-box">
                      <a href="team-details.html">
                        <img src="assets/images/resource/team-2.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="lower-content">
                      <div className="box">
                        <h5>
                          <a href="team-details.html">Mrs. Tawanpreet Bhasin</a>
                        </h5>
                        <span className="designation">Director</span>
                        {/* <ul className="social-links clearfix">
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                <div
                  className="team-block-two wow fadeInUp"
                  data-wow-delay="400ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <figure className="image-box">
                      <a href="team-details.html">
                        <img src="assets/images/resource/team-3.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="lower-content">
                      <div className="box">
                        <h5>
                          <a href="team-details.html">Mr. Mandeep Singh</a>
                        </h5>
                        <span className="designation">Rental Manager</span>
                        {/* <ul className="social-links clearfix">
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                <div
                  className="team-block-two wow fadeInUp"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <figure className="image-box">
                      <a href="team-details.html">
                        <img src="assets/images/resource/team-2.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="lower-content">
                      <div className="box">
                        <h5>
                          <a href="team-details.html">Dummy Dummy</a>
                        </h5>
                        <span className="designation">Chief Executive</span>
                        {/* <ul className="social-links clearfix">
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="index-4.html">
                              <i className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}
