import React from 'react'
import CountTo from 'react-count-to';
export default function FunFact() {
  return (
    <section className="funfact-style-three centred">
          <div
            className="pattern-layer float-bob-x-2"
            style={{ backgroundImage: "url(assets/images/icons/pattern-18.png)" }}
          />
          <div className="auto-container">
            <div className="sec-title">
              <div className="title-two">
                <p>
                  <span>{"{"}</span>&nbsp;Fun Facts&nbsp;<span>{"}"}</span>
                </p>
              </div>
              <h3>
                We are enriched with
                <br />
                our awesome funfacts
              </h3>
            </div>
            <div className="upper-box">
              <div className="row clearfix">
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                  <div className="counter-block-three">
                    <div
                      className="counter-block wow slideInUp"
                    >
                      <div className="count-outer count-box">
                        <span className="count-text" data-speed={1500} data-stop={24}>
                        <CountTo to={100} speed={2000} />
                        </span>
                        {/* k */}
                      </div>
                      <h3>
                        Machines 
                        {/* <span>Clients</span> */}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                  <div className="counter-block-three">
                    <div
                      className="counter-block wow slideInUp"
                    >
                      <div className="count-outer count-box">
                        <span className="count-text" data-speed={1500} data-stop={36}>
                        <CountTo to={50} speed={2000} />
                        </span>
                      </div>
                      <h3>
                        Expert <span>Team</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                  <div className="counter-block-three">
                    <div
                      className="counter-block wow slideInUp"
                    >
                      <div className="count-outer count-box">
                        <span className="count-text" data-speed={1500} data-stop={15}>
                        <CountTo to={500} speed={2000} />
                        </span>
                      </div>
                      <h3>
                        Happy <span>Clients</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                  <div className="counter-block-three">
                    <div
                      className="counter-block wow slideInUp"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="count-outer count-box">
                        <span
                          className="count-text"
                        >
                          <CountTo to={1234} speed={2000} />
                        </span>
                      </div>
                      <h3>
                        Positive <span>Reviews</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="lower-box">
              <div className="inner-box">
                <h2>Subscribe to our newsletter!</h2>
                <p>And Get the latest update of our company</p>
                <form
                  action="https://azim.commonsupport.com/Emu/index-3.html"
                  method="post"
                  className="subscribe-form"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="email address"
                      required=""
                    />
                    <button className="theme-btn style-four" type="submit">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </section>
  )
}
