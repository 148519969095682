import React from 'react'

export default function FAQ() {
  return (
    <section className="faq-section bg-color-1">
    <div className="bubble-dotted">
      <span className="dotted dotted-1" />
      <span className="dotted dotted-2" />
      <span className="dotted dotted-3" />
      <span className="dotted dotted-4" />
      <span className="dotted dotted-5" />
      <span className="dotted dotted-6" />
      <span className="dotted dotted-7" />
      <span className="dotted dotted-8" />
      <span className="dotted dotted-9" />
      <span className="dotted dotted-10" />
    </div>
    <div className="auto-container">
      <div
        className="sec-title centred wow fadeInDown animated"
        data-wow-delay="00ms"
      >
        <div className="title-two">
          <p>
            <span>{"{"}</span>&nbsp;FAQ&nbsp;<span>{"}"}</span>
          </p>
        </div>
        <h3>
          Lear more from our
          <br />
          frequently asked question!
        </h3>
      </div>
      <div className="row clearfix">
        <div className="col-lg-10 col-md-12 col-sm-12 big-column offset-lg-1">
          <div className="inner-content">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 inner-column">
                <div
                  className="inner-box wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div className="single-item">
                    <h4>What should I include in SEO?</h4>
                    <p>
                      Lorem ipsum dolor sit amet consecte tur adipiscing elit
                      sed eiusmod tempor incididunt ut labore.
                    </p>
                  </div>
                  <div className="single-item">
                    <h4>How does the Moodle SEO work?</h4>
                    <p>
                      The apps you build with Ionic leverage the power and
                      stability of the open web - the most time-tested universal
                      runtime in the world. The web has been around over two
                      decades.
                    </p>
                  </div>
                  <div className="single-item">
                    <h4>Does disabling SEO free up space?</h4>
                    <p>
                      Lorem ipsum dolor sit amet consecte tur adipiscing elit
                      sed eiusmod tempor incididunt ut labore.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 inner-column">
                <div
                  className="inner-box wow fadeInRight animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div className="single-item">
                    <h4>Can users choose to install the SEO app?</h4>
                    <p>
                      We’re a company built on open source. It all began with
                      Ionic Framework - the world's most popular open source for
                      building cross-platform mobile and Progressive Web Apps.
                    </p>
                  </div>
                  <div className="single-item">
                    <h4>How do I disable installed SEO apps?</h4>
                    <p>
                      Lorem ipsum dolor sit amet consecte tur adipiscing elit
                      sed do eiusmod tempor incididunt ut labore.
                    </p>
                  </div>
                  <div className="single-item">
                    <h4>Why are mobile SEO apps important?</h4>
                    <p>
                      Lorem ipsum dolor sit amet consecte tur adipiscing elit
                      sed do eiusmod tempor incididunt ut labore.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lower-text centred">
        <p>
          Still have a question? <span>Contact us:</span>&nbsp;
          <a href="mailto:example@info.com">info@keartsolutions.com</a>
        </p>
      </div>
    </div>
  </section>
  )
}
