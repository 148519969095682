
// src/CarouselComponent.js
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './CarouselComponent.css'; // Optional: For additional custom styling
function Testimonial (){
  const options = {
    items: 2.5,
    nav: true,        // Show navigation arrows
    rewind: true,     // Rewind to start when reaching the end (often used with loop)
    autoplay: true,  // Automatically play the carousel
    loop: true,       // Infinite loop
    dots: false       // Hide the navigation dots
};

    return (
      <>
      <section className="testimonial-section style-two">
      <div className="anim-icon wow zoomIn animated" data-wow-delay="00ms">
        <div
          className="icon icon-1 rotate-me"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-1.png)" }}
        />
        <div
          className="icon icon-2 float-bob-x"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-2.png)" }}
        />
        <div className="icon icon-3 float-bob-y" />
        <div
          className="icon icon-4 rotate-me"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-3.png)" }}
        />
        <div
          className="icon icon-5 float-bob-x"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-4.png)" }}
        />
        <div
          className="icon icon-6 rotate-me"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-5.png)" }}
        />
        <div
          className="icon icon-7 rotate-me"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-5.png)" }}
        />
        <div
          className="icon icon-8 float-bob-y"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-4.png)" }}
        />
        <div
          className="icon icon-9 rotate-me"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-3.png)" }}
        />
        <div className="icon icon-10 float-bob-x" />
        <div
          className="icon icon-11 rotate-me"
          style={{ backgroundImage: "url(assets/images/icons/anim-icon-1.png)" }}
        />
      </div>
      <div className="auto-container">
        <div className="sec-title centred">
          <div className="title-two">
            <p>
              <span>{"{"}</span>&nbsp;Testimonials&nbsp;<span>{"}"}</span>
            </p>
          </div>
          <h3>
            We serve our clients with the
            <br />
            best of our capacity
          </h3>
        </div>
  
  
  
        {/* <div className="testimonial-carousel owl-carousel owl-theme owl-dots-none"> */}
        <OwlCarousel className="owl-theme" {...options} >
          <div className="testimonial-content">
            <div className="inner-box">
              <div className="top-inner">
                <div
                  className="pattern-layer"
                  style={{
                    backgroundImage: "url(assets/images/icons/pattern-20.png)"
                  }}
                />
                <div className="text">
                  <p>
                    Lorem ipsum dolor sit amet consecter tony adipiscing elitsed
                    do eiusmodi tempor roar incididunt ut laboreet dolore magna as
                    the opposed to using. Type of partnership or Malta. The
                    limited todot liability.
                  </p>
                </div>
                <ul className="rating clearfix">
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                </ul>
              </div>
              <div className="author-info">
                <figure className="image-box">
                  <img src="assets/images/resource/testimonial-1.png" alt="" />
                </figure>
                <h3>Nepoli Dewan</h3>
                <span className="designation">SEO Manager</span>
              </div>
            </div>
          </div>
          <div className="testimonial-content">
            <div className="inner-box">
              <div className="top-inner">
                <div
                  className="pattern-layer"
                  style={{
                    backgroundImage: "url(assets/images/icons/pattern-20.png)"
                  }}
                />
                <div className="text">
                  <p>
                    Lorem ipsum dolor sit amet consecter tony adipiscing elitsed
                    do eiusmodi tempor roar incididunt ut laboreet dolore magna as
                    the opposed to using. Type of partnership or Malta. The
                    limited todot liability.
                  </p>
                </div>
                <ul className="rating clearfix">
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                </ul>
              </div>
              <div className="author-info">
                <figure className="image-box">
                  <img src="assets/images/resource/testimonial-2.png" alt="" />
                </figure>
                <h3>Rameli Andrea</h3>
                <span className="designation">SEO Manager</span>
              </div>
            </div>
          </div>
          <div className="testimonial-content">
            <div className="inner-box">
              <div className="top-inner">
                <div
                  className="pattern-layer"
                  style={{
                    backgroundImage: "url(assets/images/icons/pattern-20.png)"
                  }}
                />
                <div className="text">
                  <p>
                    Lorem ipsum dolor sit amet consecter tony adipiscing elitsed
                    do eiusmodi tempor roar incididunt ut laboreet dolore magna as
                    the opposed to using. Type of partnership or Malta. The
                    limited todot liability.
                  </p>
                </div>
                <ul className="rating clearfix">
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                </ul>
              </div>
              <div className="author-info">
                <figure className="image-box">
                  <img src="assets/images/resource/testimonial-3.png" alt="" />
                </figure>
                <h3>Nelima Frank</h3>
                <span className="designation">SEO Manager</span>
              </div>
            </div>
          </div>
        {/* </div> */}
        </OwlCarousel>
      </div>
    </section>
    

    {/* <div className="carousel-container" >
      <OwlCarousel className="owl-theme" {...options} >
        <div className="item"><img src="images/banner/1.jpg"/></div>
        <div className="item"><img src="images/banner/2.jpg"/></div>
        <div className="item"><img src="images/banner/4.jpg"/></div>
      </OwlCarousel>
    </div> */}
    </>
  );
};

export default Testimonial;
