import React from 'react'

export default function BannerSection1() {
  return (
    <section className="banner-style-three" style={{maxWidth:"100vw", width:"100%"}}>
      <div className="pattern-layer">
        <div
          className="pattern pattern-1"
          style={{ backgroundImage: "url(assets/images/icons/pattern-14.png)",backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}
        />
        <div
          className="pattern pattern-2"
          style={{ backgroundImage: "url(assets/images/icons/pattern-15.png)",  }}
        />
        <div
          className="pattern pattern-3"
          style={{ backgroundImage: "url(assets/images/icons/pattern-16.png)" }}
        />
      </div>
      <div className="parallax-scene parallax-scene-2 parallax-icon">
        <span data-depth="0.40" className="parallax-layer icon icon-1" />
        <span data-depth="0.50" className="parallax-layer icon icon-2" />
        <span data-depth="0.30" className="parallax-layer icon icon-3" />
      </div>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-7 col-md-12 col-sm-12 content-column">
            <div className="content-box">
              <h1>
                Boost your Operation With <br /><span>FIX RENTAL</span>
              </h1>
              <div className="text">
                <p>
                We offer flexible and comprehensive heavy machinery rental plans and
                  <br />
                  services, ensuring you have the right equipment to power your projects efficiently.
                </p>
              </div>
              <div
                className="btn-box clearfix wow slideInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                {/* <a
                  href="https://www.youtube.com/watch?v=nfP5N9Yc72A&t=28s"
                  className="lightbox-image video-btn"
                  data-caption=""
                >
                  <i className="fas fa-play" />
                  <span className="border-animation" />
                </a> */}
                <a href="#contact" className="theme-btn style-four">
                  Contact us
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 image-column">
            <div className="image-box">
              <figure className="image image-1">
                <img src="assets/images/resource/dashboard-1.png" alt="" />
              </figure>
              <figure className="image image-2 float-bob-y">
                <img src="assets/images/resource/women-1.png" alt="" />
              </figure>
              <figure
                className="image image-3 wow slideInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <img src="assets/images/resource/men-1.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
