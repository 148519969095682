import React, { useEffect } from 'react'
import $ from 'jquery';
import WOW from 'wowjs';
import Parallax from 'parallax-js';

export default function Header() {
    
    useEffect(()=>{
        function headerStyle() {
            if ($('.main-header').length) {
              var windowpos = $(window).scrollTop();
              var siteHeader = $('.main-header');
              var scrollLink = $('.scroll-top');
              if (windowpos >= 110) {
                siteHeader.addClass('fixed-header');
                scrollLink.addClass('open');
              } else {
                siteHeader.removeClass('fixed-header');
                scrollLink.removeClass('open');
              }
            }
          }

          $(window).on('scroll', function () {
            headerStyle()
          });
      
    },[])
    return (
        <header className="main-header style-three">
            <div className="auto-container">
                <div className="header-upper">
                    <div className="outer-box clearfix">
                        <div className="logo-box pull-left">
                            <figure className="logo">
                                <a href="#">
                                    <img style={{width:"221px",}} src="assets/images/logo-light.svg" alt="" />
                                </a>
                            </figure>
                        </div>
                        <div className="nav-box pull-right clearfix">
                            <div className="menu-area pull-left">
                                {/*Mobile Navigation Toggler*/}
                                <div className="mobile-nav-toggler">
                                    <i className="icon-bar" />
                                    <i className="icon-bar" />
                                    <i className="icon-bar" />
                                </div>
                                <nav className="main-menu navbar-expand-md navbar-light">
                                    <div
                                        className="collapse navbar-collapse show clearfix"
                                        id="navbarSupportedContent"
                                    >
                                        <ul className="navigation clearfix">
                                            <li className="current dropdown">
                                                <a href="#banner-section1">Home</a>
                                            </li>
                                            <li className="dropdown">
                                                <a href="#services">Services</a>
                                            </li>
                                            <li>
                                                <a href="#gallery">Gallery</a>
                                            </li>
                                            <li>
                                                <a href="#contact">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*sticky Header*/}
            <div className="sticky-header">
                <div className="container clearfix">
                    <figure className="logo-box">
                        <a href="#">
                        <img style={{width:"201px",}} src="assets/images/logo-light.svg" alt="" />
                        </a>
                    </figure>
                    <div className="menu-area">
                        <nav className="main-menu clearfix">
                            {/*Keep This Empty / Menu will come through Javascript*/}
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}
