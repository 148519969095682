import React, { useEffect } from 'react'
import $ from 'jquery';
import WOW from 'wowjs';
import Parallax from 'parallax-js';

export default function Menu() {
   useEffect(()=>{
    if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.main-header .menu-area .main-menu').html();

        if (!$('.mobile-menu .menu-box .menu-outer').hasClass('content-added')) {
            $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent).addClass('content-added');
        }

        if (!$('.sticky-header .main-menu').hasClass('content-added')) {
            $('.sticky-header .main-menu').append(mobileMenuContent).addClass('content-added');
        }

        // Dropdown Button
        $('.mobile-menu li.dropdown .dropdown-btn').on('click', function () {
            $(this).toggleClass('open');
            $(this).prev('ul').slideToggle(500);
        });

        // Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
            $('body').addClass('mobile-menu-visible');
        });

        // Menu Toggle Btn
        $('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function () {
            $('body').removeClass('mobile-menu-visible');
        });
    }
   },[])
    return (
        <div className="mobile-menu">
            <div className="menu-backdrop" />
            <div className="close-btn">
                <i className="fas fa-times" />
            </div>
            <nav className="menu-box">
                <div className="nav-logo">
                    <a href="#">
                        <img src="assets/images/footer-logo.png" alt="" title="" />
                    </a>
                </div>
                <div className="menu-outer">
                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                </div>
                <div className="contact-info">
                    <h4>Contact Info</h4>
                    <ul>
                        <li>Chicago 12, Melborne City, USA</li>
                        <li>
                            <a href="tel:+8801682648101">+88 01682648101</a>
                        </li>
                        <li>
                            <a href="mailto:info@example.com">info@example.com</a>
                        </li>
                    </ul>
                </div>
                <div className="social-links">
                    <ul className="clearfix">
                        <li>
                            <a href="index-4.html">
                                <span className="fab fa-twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="index-4.html">
                                <span className="fab fa-facebook-square" />
                            </a>
                        </li>
                        <li>
                            <a href="index-4.html">
                                <span className="fab fa-pinterest-p" />
                            </a>
                        </li>
                        <li>
                            <a href="index-4.html">
                                <span className="fab fa-instagram" />
                            </a>
                        </li>
                        <li>
                            <a href="index-4.html">
                                <span className="fab fa-youtube" />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}
