import React from 'react'

export default function Footer() {
  return (
    <footer className="main-footer style-three">
          <div className="footer-top">
            <div className="auto-container">
              <div className="widget-section">
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                    <div
                      className="logo-widget footer-widget wow fadeInUp animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <figure className="footer-logo">
                        <a href="#">
                        <img style={{width:"201px",}} src="assets/images/logo-light.svg" alt="" />
                        </a>
                      </figure>
                      <div className="widget-content">
                        <ul className="clearfix">
                          <li>
                            <i className="fas fa-map-marker-alt" />
                            Kesar Tower, Ring Road No. - 2, Gondwara, Bhanpuri, Bilaspur Raod, Raipur, Chhattisgarh,
                            <br/>India - 492003
                          </li>
                          <li>
                            <i className="fas fa-phone" />
                            <a href="tel:+91 9589696525">+91 9589696525</a>
                          </li>
                          <li>
                            <strong>Open Hours:</strong>
                            <br />
                            Mon - Sat: 10 am - 6.30 pm,
                            <br />
                            Sunday: CLOSED
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                    <div
                      className="links-widget footer-widget wow fadeInUp animated"
                      data-wow-delay="300ms"
                      data-wow-duration="1500ms"
                    >
                      <h3 className="widget-title">
                        <span>Links</span>
                      </h3>
                      <div className="widget-content">
                        <ul className="links-list clearfix">
                          <li>
                            <a href="#banner-section1">Home</a>
                          </li>
                          <li>
                            <a href="#services">Services</a>
                          </li>
                          <li>
                            <a href="#contact">Contact Us</a>
                          </li>
                          <li>
                            <a href="#gallery">Gallery</a>
                          </li>
                          <li>
                            <a href="#about">About us</a>
                          </li>
                          <li>
                            <a href="#testimonial">Testimonials</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 footer-column">
                    <div
                      className="subscribe-widget footer-widget wow fadeInUp animated"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <h3 className="widget-title">
                        <span>Social Links</span>
                      </h3>
                      <h5 style={{marginBottom:"2%"}}>Connect with us on</h5>
                      {/*
                      <div className="widget-content">
                        <div className="text">
                          <p>Send us a newsletter to get update</p>
                        </div>
                        <form
                          action="https://azim.commonsupport.com/Emu/contact.html"
                          method="post"
                          className="subscribe-form"
                        >
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              placeholder="Your mail address"
                              required=""
                            />
                            <button className="submit">
                              <i className="far fa-paper-plane" />
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="download-btn clearfix">
                        <figure className="image">
                          <a href="index-5.html">
                            <img src="assets/images/icons/app-store.png" alt="" />
                          </a>
                        </figure>
                        <figure className="image">
                          <a href="index-5.html">
                            <img src="assets/images/icons/play-store.png" alt="" />
                          </a>
                        </figure>
                      </div> */}
                      <ul className="social-links clearfix">
                        <li>
                          <a target='_blank' href="https://www.facebook.com/Liugongcg">
                            <i style={{fontSize:"22px"}} className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li style={{marginLeft:"9%"}}>
                          <a target='_blank' href="https://www.instagram.com/kesarearthsolutions/?hl=en">
                            <i style={{fontSize:"22px"}} className="fab fa-instagram" />
                          </a>
                        </li>
                        <li style={{marginLeft:"9%"}}>
                          <a target='_blank' href="https://kearthsolutions.com/">
                            <i style={{fontSize:"22px"}} className="fa fa-link" />
                          </a>
                        </li>
                        {/* <li>
                          <a href="index-4.html">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="index-4.html">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li> */}
                        {/* <li>
                          <a href="index-4.html">
                            <i className="fab fa-skype" />
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom text-center">
            <div className="auto-container">
              <div className="copyright">
                <p>
                  Made with ❤️&nbsp;by
                  <a target='_blank' href="https://fixingdots.com/">&nbsp;Fixing Dots</a>
                </p>
              </div>
            </div>
          </div>
        </footer>
  )
}
