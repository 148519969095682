import React from 'react'

export default function Services() {
    return (
        <section className="featured-section style-two bg-color-1">
            <div className="bubble-dotted">
                <span className="dotted dotted-1" />
                <span className="dotted dotted-2" />
                <span className="dotted dotted-3" />
                <span className="dotted dotted-4" />
                <span className="dotted dotted-5" />
                <span className="dotted dotted-6" />
                <span className="dotted dotted-7" />
                <span className="dotted dotted-8" />
                <span className="dotted dotted-9" />
                <span className="dotted dotted-10" />
            </div>
            <div className="auto-container">
                <div className="sec-title centred">
                    <div className="title-two">
                        <p>
                            <span>{"{"}</span>&nbsp;Fetaured service&nbsp;<span>{"}"}</span>
                        </p>
                    </div>
                    <h3>
                        Additional Services that will
                        <br />
                        Grow Your Business
                    </h3>
                    <p>
                        As a trusted partner in the heavy equipment industry, I help businesses access top-quality machinery rentals,
                        <br />
                        ensuring they have the tools they need to complete their projects efficiently and effectively.
                    </p>
                </div>
                <div
                    className="tabs-box wow zoomIn animated"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                >
                    <div className="tabs-content">
                        <div className="tab active-tab" id="tab-3">
                            <div className="row clearfix">
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-configuration" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Pay-Per-Click</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Google Ad campaigns are an effective way to target
                                                    receptive audience they get you.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-statistics" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Branding</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Make sure your brand assets contribute to your company’s
                                                    personality and reputation.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-social-media" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Content Marketing</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Your content is an integral part of your SEO efforts and
                                                    online marketing strategy.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-chat" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Email Marketing</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Drive sales, build and support a wide range of
                                                    initiatives through a strategic email.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab" id="tab-4">
                            <div className="row clearfix">
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-configuration" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Digital marketing</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Google Ad campaigns are an effective way to target
                                                    receptive audience they get you.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-statistics" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Web Design</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Make sure your brand assets contribute to your company’s
                                                    personality and reputation.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-social-media" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Custom Software</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Your content is an integral part of your SEO efforts and
                                                    online marketing strategy.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                    <div className="feature-block-one">
                                        <div className="hover-content" />
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="shadow-box" />
                                                <i className="flaticon-chat" />
                                            </div>
                                            <h4>
                                                <a href="index-2.html">Email Marketing</a>
                                            </h4>
                                            <div className="text">
                                                <p>
                                                    Drive sales, build and support a wide range of
                                                    initiatives through a strategic email.
                                                </p>
                                            </div>
                                            <div className="link">
                                                <a href="contact.html">
                                                    Contact us
                                                    <i className="flaticon-arrow-pointing-to-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="tab-btn-box">
                        <ul className="tab-btns tab-buttons clearfix">
                            <li className="tab-btn active-btn" data-tab="#tab-3" />
                            <li className="tab-btn" data-tab="#tab-4" />
                        </ul>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
